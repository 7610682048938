

import Vue from 'vue';
import { scrollToTop } from './functions';

export default Vue.extend({
  name: 'app',
  methods: {
    home() {
      this.$router.push("/");
      scrollToTop();
    }
  }
});
