
import Vue from 'vue';
import Chapters, { Chapter, getChapterTitle } from '../chapters';
import { scrollToTop } from '@/functions';


export default Vue.extend({
  name: 'ChapterView',
  computed: {
    chapter(): Chapter {
      return Chapters[this.chapterId];
    },
    chapterId(): number {
      return parseInt(this.$route.params.chapterId, 10);
    },
    hasPreviousChapter(): boolean {
      return this.chapterId > 1;
    },
    hasNextChapter(): boolean {
      return this.chapterId < Object.keys(Chapters).length
    },
  },
  methods: {
    tableOfContents() {
      this.$router.push("/");
      scrollToTop();
    },

    pClass(paragraph: string | string[]): string {
      if (typeof paragraph === "string") {
        return "w-100";
      }
      return "table-item";
    },

    getLines(paragraph: string | string[]): string[] {
      if (typeof paragraph === "string") {
        return [paragraph];
      }
      return paragraph;
    },

    isImage(paragraph: string | string[]): boolean {
      const line = paragraph.toString();
      return line.endsWith(".png") || line.endsWith(".jpg");

    },
    trim(paragraph: string | string[]): string {

      let line = paragraph.toString();
      if (line.startsWith("/")) {
        line = line.substring(1);
      }
      return line.replace(/ +(?= )/g, '').trim();

    },
    nextChapter() {
      this.$router.push("/chapter/" + (this.chapterId + 1));
      scrollToTop();
    },
    previousChapter() {
      this.$router.push("/chapter/" + (this.chapterId - 1));
      scrollToTop();
    },
    getChapterTitle(chapter: Chapter): string {
      return getChapterTitle(chapter);
    }
  }
});
