
import Vue from 'vue';
import Chapters, { Chapter, getChapterTitle } from '../chapters';
import { scrollToTop } from '@/functions';

export default Vue.extend({
  name: 'HomeView',
  data() {
    return {
      Chapters
    };
  },
  methods: {
    selectChapter(chapterId: number): void {
      this.$router.push("/chapter/" + chapterId);
      scrollToTop();
    },
    getChapterTitle(chapter: Chapter): string {
      return getChapterTitle(chapter);
    }
  }
});
